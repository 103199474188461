import React, { Component } from "react";
import { Button, Checkbox, Form, Grid, Modal, Segment } from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import 'semantic-ui-css/semantic.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";

import DmpNavbar from "./dmpNavbar.jsx";
import Footer from "./footer.jsx";
import UserContext from "../userInfo/UserContext.js";

import { OrganizationContext } from "../components/OrganizationInfo/OrganizationContext.js";
import UserProvider from '../userInfo/UserProvider.jsx';
import OrganizationProvider from '../components/OrganizationInfo/OrganizationProvider.jsx';
import CategoryProvider from '../components/CategoryInfo/CategoryProvider.jsx';
import { CategoryContext } from "../components/CategoryInfo/CategoryContext.js";
import ajax from '../commons/agent.js'

import UserDetails from '../userInfo/UserDetails.js';
import { BackTop } from 'antd';
import DogControlNotices from './dcns/DogControlNotices.jsx';
import DcnsDetailPage from './dcns/DcnsDetailPage.jsx';
import DcnDashboard from "./dcns/DcnDashboard.jsx";
import UploadDcn from './dcns/UploadDcn.jsx';
import CreateDcn from "./dcns/createDcn/CreateDcn.jsx";
import PlatformAdminDashboard from "./admin/PlatformAdminDashboard.jsx";
import Worklist from "./admin/Worklist.jsx";
import OrganizationSignUp from "./signUp/OrganizationSignUp.jsx";
import UserSignUp from "./signUp/UserSignUp.jsx";
import PartnerListing from "./partners/partnerListing.jsx";
import PartnerDetails from "./partners/partnerDetails.jsx";
import TransferRequests from "./dcns/TransferRequests.jsx";
import HelpPostLogin from "./helpdesk/HelpPostLogin.jsx";
import HelpdeskQueryPostLogin from "./helpdesk/HelpdeskQueryPostLogin.jsx";
import HelpdeskQueryDashboard from "./helpdesk/HelpdeskQueryDashboard.jsx";
import OrganizationRequest from "./signUp/OrganizationRequest.jsx";
import UserRequest from "./signUp/UserRequest.jsx";
import { TermsAndConditions } from "./terms/TermsAndConditions.jsx";
import { PrivacyPolicy } from "./terms/PrivacyPolicy.jsx";
import DisplayMessage from "../commons/displayMessage.jsx";
import utility from "../commons/utility.js";
import Reports from "./reports/Reports.jsx";



export default class DcnUser extends Component {
   constructor(props) {
      super(props)
      this.state = {
         messageType: '', messageHeader: '', message: [],
         showTermsModal: false,
         isConsentAccepted: true,
         isLatestTermAccepted: true,
         isLatestPrivacyPolicyAccepted: true,
         rememberConsent: false,
         isTermAndConditionChecked: false,
         isPrivacyPolicyChecked: false,
         showTermAndCondition: false,
         showPrivacyPolicy: false,
      }
   }

   componentDidMount() {
      // console.log(UserDetails);
      this.onLoad()
   }
   onLoad = () => {
      let self = this
      ajax.OrganizationMaster.hasAcceptedLatestTerms().end((err, response) => {
         self.setState({ isAcceptingTermsAndPolicy: false });
         if (!err && response) {
            self.setState({
               showTermsModal: !response.body.isLatestTermAccepted || !response.body.isLatestPrivacyPolicyAccepted,
               isConsentAccepted: response.body.isConsentAccepted,
               isLatestTermAccepted: response.body.isLatestTermAccepted,
               isLatestPrivacyPolicyAccepted: response.body.isLatestPrivacyPolicyAccepted,
            });
         } else {
            utility.loadDisplayMessage(self, "error", "Something Went Wrong.", ["Problem accepting Privacy Policy.", "Please contact system administrator.",
            ]);
         }
      })
   }

   acceptTermsAndPolicy = () => {
      let self = this
      if (!this.state.isLatestTermAccepted) {
         self.setState({ isAcceptingTerms: true });
         ajax.OrganizationMaster.acceptTerms().end((err, response) => {
            self.setState({ isAcceptingTerms: false });
            utility.loadingModalLoader(self, "Please wait. redirecting...");
            if (!err && response) {
               self.onLoad()
            } else {
               utility.loadDisplayMessage(self, "error", "Something Went Wrong.", ["Problem accepting Terms and conditions.", "Please contact system administrator.",
               ]);
            }
         });
      }

      if (!this.state.isLatestPrivacyPolicyAccepted) {
         self.setState({ isAcceptingPolicy: true });
         ajax.OrganizationMaster.acceptPolicy().end((err, response) => {
            self.setState({ isAcceptingPolicy: false });
            utility.loadingModalLoader(self, "Please wait. redirecting...");
            if (!err && response) {
               self.onLoad()
            } else {
               utility.loadDisplayMessage(self, "error", "Something Went Wrong.", ["Problem accepting Privacy Policy.", "Please contact system administrator.",
               ]);
            }
         })
      }
   }

   static contextType = UserContext;
   render() {
      //console.log("dmpUser")
      // console.log(UserDetails);
      return (
         <React.Fragment>
            <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
            <OrganizationProvider>
               {({ fetchOrganizationList, fetchOrganizationName, fetchOrganizationProfile, fetchOrganizationWebsite, fetchOrganizationEmail, fetchOrganizationCategory, fetchOrganization, reloadOrgContext }) => (
                  <UserProvider >
                     <OrganizationContext.Consumer>
                        {({ orgFetched, organizationList }) => (
                           <UserContext.Consumer>
                              {({ userDetails }) => (
                                 (orgFetched && userDetails) && <>
                                    <Grid className="mx-0 px-0">
                                       <Grid.Column width={16} className="m-0" style={{ minHeight: 'calc(100vh - 63px)' }}>

                                          <DmpNavbar className="navBg" fetchOrganizationName={fetchOrganizationName} fetchOrganizationCategory={fetchOrganizationCategory} />
                                          <Switch>
                                             {UserDetails.platformadmin ?
                                                <>
                                                   <Route exact exact path="/dashboard" render={() => <PlatformAdminDashboard />} />
                                                   <Route exact exact path="/dashboard/approvals" render={() => <Worklist />} />
                                                   <Route exact path="/dashboard/registerOrganisation" render={() => <OrganizationSignUp reloadOrgContext={reloadOrgContext} />} />
                                                   <Route exact path="/dashboard/editOrganisation" render={() => <OrganizationSignUp updateMode={true} reloadOrgContext={reloadOrgContext} />} />
                                                   <Route exact path="/dashboard/registerAgent/:bpid" render={() => <UserSignUp fetchOrganizationCategory={fetchOrganizationCategory} fetchOrganizationList={fetchOrganizationList} />} />
                                                   <Route exact path="/dashboard/editAgent/:bpid" render={() => <UserSignUp updateMode={true} fetchOrganizationCategory={fetchOrganizationCategory} fetchOrganizationList={fetchOrganizationList} />} />
                                                   {/* <Route exact path="/dashboard/orgrequest" exact render={() => <OrganizationRequest />} /> */}
                                                   {/* <Route exact path="/dashboard/userrequest" exact render={() => <UserRequest />} /> */}
                                                   <Route exact path="/dashboard/organisations" render={() => <PartnerListing fetchOrganizationList={fetchOrganizationList} />} />
                                                   <Route exact path="/dashboard/organisations/:bpid" render={() => <PartnerDetails fetchOrganizationCategory={fetchOrganizationCategory} fetchOrganizationList={fetchOrganizationList} fetchOrganizationName={fetchOrganizationName} />} />
                                                </>
                                                :
                                                <>
                                                   <Route exact exact path='/dashboard' render={() => <DcnDashboard fetchOrganizationList={fetchOrganizationList} />} />

                                                   <Route exact exact path="/dashboard/approvals" render={() => <Worklist />} />
                                                   <Route exact path="/dashboard/registerOrganisation" render={() => <OrganizationSignUp reloadOrgContext={reloadOrgContext} />} />
                                                   <Route exact path="/dashboard/editOrganisation" render={() => <OrganizationSignUp updateMode={true} reloadOrgContext={reloadOrgContext} />} />
                                                   <Route exact path="/dashboard/registerAgent/:bpid" render={() => <UserSignUp fetchOrganizationCategory={fetchOrganizationCategory} fetchOrganizationList={fetchOrganizationList} />} />
                                                   <Route exact path="/dashboard/editAgent/:bpid" render={() => <UserSignUp updateMode={true} fetchOrganizationCategory={fetchOrganizationCategory} fetchOrganizationList={fetchOrganizationList} />} />
                                                   <Route exact path="/dashboard/organisations" render={() => <PartnerListing fetchOrganizationCategory={fetchOrganizationCategory} fetchOrganizationList={fetchOrganizationList} />} />
                                                   <Route exact path="/dashboard/organisations/:bpid" render={() => <PartnerDetails fetchOrganizationCategory={fetchOrganizationCategory} fetchOrganizationList={fetchOrganizationList} fetchOrganizationName={fetchOrganizationName} />} />

                                                   <Route exact exact path='/dashboard/dogControlNotices' render={() => <DogControlNotices fetchOrganizationCategory={fetchOrganizationCategory}/>} />
                                                   <Route exact path='/dashboard/dogControlNotices/dcnsdetailpage/:dcnId' render={() => <DcnsDetailPage fetchOrganizationList={fetchOrganizationList} fetchOrganizationName={fetchOrganizationName} fetchOrganizationCategory={fetchOrganizationCategory} />} />
                                                   <Route exact path='/dashboard/uploadDcn' render={() => <CreateDcn fetchOrganizationCategory={fetchOrganizationCategory} fetchOrganizationList={fetchOrganizationList} fetchOrganization={fetchOrganization} />} />
                                                   <Route exact path='/dashboard/transferRequests' render={() => <TransferRequests fetchOrganizationCategory={fetchOrganizationCategory} fetchOrganizationList={fetchOrganizationList} fetchOrganizationName={fetchOrganizationName} />} />
                                                   <Route exact path="/dashboard/help" render={() => <HelpPostLogin />} />
                                                   <Route exact path="/dashboard/helpdeskQuery" render={() => <HelpdeskQueryPostLogin />} />
                                                   <Route exact path="/dashboard/helpdeskQueryDashboard" render={() => <HelpdeskQueryDashboard fetchOrganizationName={fetchOrganizationName} fetchOrganizationList={fetchOrganizationList} fetchOrganizationCategory={fetchOrganizationCategory} />} />
                                                   <Route exact path="/dashboard/reports" render={() => <Reports />} />
                                                </>
                                             }
                                          </Switch>
                                       </Grid.Column>
                                    </Grid>

                                    <Grid.Column width={16}>
                                       <Footer />
                                    </Grid.Column>
                                    <BackTop />

                                    <Modal style={{ width: '388px' }} open={this.state.showTermsModal} centered={false} onClose={() => this.props.onClose()} dimmer='inverted' size='mini'>
                                       <Modal.Content className='py-5px'>
                                          {!this.state.isConsentAccepted && <>
                                             <div className='textBig textBold mt-5px'>Consent Notice</div>
                                             <div className='textMedium my-5px text-center'>dcn.scot would need to: </div>
                                             <Segment className='mt-5px p-5px bgGrey94'>
                                                <div className='textNormal my-5px '>Know some of your details</div>
                                                <div className='textSmall my-5px'>By selecting following attributes you agree to share them with dcn.scot</div>
                                                <Form >
                                                   <Form.Field required><Checkbox disabled checked={true} label='Forename' /></Form.Field>
                                                   <Form.Field required><Checkbox disabled checked={true} label='Surname' /></Form.Field>
                                                   <Form.Field required><Checkbox disabled checked={true} label='Email' /></Form.Field>
                                                   <Form.Field required><Checkbox disabled checked={true} label='Contact Number' /></Form.Field>
                                                </Form>
                                                <div className='textSmall my-5px'>Mandatory claims are marked with an asterisk(*)</div>
                                             </Segment>
                                             <Form >
                                                <Form.Field required> <Checkbox checked={this.state.rememberConsent} label='Remember my consent' onChange={() => this.setState(prev => ({ rememberConsent: !prev.rememberConsent }))} /> </Form.Field>
                                             </Form>
                                          </>}

                                          <div className='textBig textBold mt-1 mb-5px '>Acceptance of Policies</div>

                                          <Form >
                                             {!this.state.isLatestTermAccepted && <Form.Field required>
                                                <Checkbox checked={this.state.isTermAndConditionChecked} onClick={() => this.setState({ showTermAndCondition: true })} label={<label>I have read and agreed to the <a>Terms and Conditions</a></label>} />
                                             </Form.Field>}
                                             {!this.state.isLatestPrivacyPolicyAccepted && <Form.Field required>
                                                <Checkbox checked={this.state.isPrivacyPolicyChecked} onClick={() => this.setState({ showPrivacyPolicy: true })} label={<label>I have read and agreed to the <a>Privacy Policy</a></label>} />
                                             </Form.Field>}
                                          </Form>
                                          <Grid>
                                             <Grid.Column computer={16} textAlign='right' className='mt-1'>
                                                <Button size='small' disabled={(!this.state.isTermAndConditionChecked && !this.state.isLatestTermAccepted) || (!this.state.isPrivacyPolicyChecked && !this.state.isLatestPrivacyPolicyAccepted) || (!this.state.rememberConsent && !this.state.isConsentAccepted)} className='customBtn1Primary mr-0' onClick={() => this.acceptTermsAndPolicy()} loading={this.state.isAcceptingTerms || this.state.isAcceptingPolicy} >Submit</Button>
                                                <div className="textSmaller"><i>By clicking submit you are accepting the terms and conditions, privacy policy and providing allowing consent for use of the above mentioned attributes.</i></div>
                                             </Grid.Column>
                                          </Grid>
                                       </Modal.Content>
                                    </Modal>


                                    <Modal open={this.state.showTermAndCondition} centered={false} onClose={() => this.props.onClose()} dimmer='inverted' size='large'>
                                       <Modal.Content className='p-0'>
                                          <TermsAndConditions fromModal />

                                          <Grid className='mb-5px'>
                                             <Grid.Column computer={16} textAlign='right' className='px-2'>
                                                <Button className='customBtn1Secondary' onClick={() => this.setState({ isTermAndConditionChecked: false, showTermAndCondition: false })}>Reject</Button>
                                                <Button className='customBtn1Primary' onClick={() => this.setState({ isTermAndConditionChecked: true, showTermAndCondition: false })}>Accept</Button>
                                             </Grid.Column>
                                          </Grid>
                                       </Modal.Content>
                                    </Modal>
                                    <Modal open={this.state.showPrivacyPolicy} centered={false} onClose={() => this.props.onClose()} dimmer='inverted' size='large'>
                                       <Modal.Content className='p-0'>
                                          <PrivacyPolicy fromModal />
                                          <Grid className='mb-5px'>
                                             <Grid.Column computer={16} textAlign='right' className='px-2'>
                                                <Button className='customBtn1Secondary' onClick={() => this.setState({ isPrivacyPolicyChecked: false, showPrivacyPolicy: false })}>Reject</Button>
                                                <Button className='customBtn1Primary' onClick={() => this.setState({ isPrivacyPolicyChecked: true, showPrivacyPolicy: false })}>Accept</Button>
                                             </Grid.Column>
                                          </Grid>
                                       </Modal.Content>
                                    </Modal>


                                 </>
                              )}
                           </UserContext.Consumer>
                        )}
                     </OrganizationContext.Consumer>
                  </UserProvider>
               )}
            </OrganizationProvider>

         </React.Fragment>
      );
   }
}
