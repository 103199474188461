class commonCss {
  constructor() {
   /* common css for all */
    this.bgColors = { "DarkBlue": "#3A5F8F", "White": "#FFFFFF", "LightBlue": "#059EC7", "Footer": "#333333","Green": "#00A651", "Orange": "#FF6633",
        "Black": "#2A2A2A", "Grey": "#DDDDDD","LightGrey": "#F2FBFE", "LightBlack": "#4E4E4E", "GreyishWhite": "#A8A8A8", "HeaderColor": "#F2F2F2",
            "TableHeaderColor": "#919191", "AttributeColor": "#FFFFEF","DarkGrey": "#707070", "SearchColor": "#A2A2A2","ButtonShandow": "#020202",
            "LightPurple": "#F8F1FC", "LightYellow": "#FFFFE0"};
    this.fontSizeMedium = {fontSize:'1em'} ;
    this.fontSizeLarge = {fontSize:'1.1em'} ;
    this.fontSizeAccurate={fontSize: '0.933em'}
    this.fontSizeSmall= {fontSize:'0.867em'};
    this.fontSizeVerySmall= {fontSize:'0.8em'};
    this.fontWeight= {fontWeight: 'bold'};
    this.fontFamily= {fontFamily:'Verdana'};
    this.textAlign= {textAlign:'center'};
      this.setCursor={cursor:'pointer'}
      this.setVisibility={ visibility: 'hidden', display: 'none'}
      this.rightFloat={float:'right'}
      this.dataProductName = { padding: '1%'};
    this.fontColor={ color: this.bgColors.White}
      this.headingFont = { color: this.bgColors.DarkBlue}
      this.buyerFont = { color: this.bgColors.Green}
      this.tableHeader = { color: this.bgColors.White, backgroundColor: this.bgColors.TableHeaderColor }
      this.loginHeaderImage = { width: '100%', height: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px'}
      this.hdrsignup = {textAlign:'center',fontfamily:'Verdana', color: this.bgColors.DarkGrey, fontSize: '1.0em'}
      this.titleText = {fontWeight:'bold', fontFamily:'Verdana', align:'bottom', fontSize: '1.5em'}
      this.innertable = {minHeight: '500px',width:'100%', backgroundColor: this.bgColors.White, borderCollapse: 'collapse'}
      this.labelStyle ={ fontSize: '0.971em', color: this.bgColors.DarkGrey,fontFamily: 'Verdana'}
      this.textBox ={ width: '100%',float: 'left',borderColor: this.bgColors.Grey }
    this.buyButton = {backgroundColor: this.bgColors.Green};
    this.disabledButton ={ backgroundColor: this.bgColors.DarkGrey}
    this.sellButton = {backgroundColor: this.bgColors.Orange};
    this.headerColor = {color: this.bgColors.DarkBlue }
      this.header = {backgroundColor: this.bgColors.DarkBlue }
    /* css for main header */
    this.firstMenuItem ={marginLeft: '1.75%'}
      this.appWidth = { minHeight: '500px', overflow: 'hidden'};
      this.logoImage = { marginLeft: '3%', width: '100%', height: '100%'};
      /* css for newProducts at home page */
      this.cardLayout = { height: '100%',marginTop: '1px', backgroundColor: this.bgColors.White,borderColor: this.bgColors.Grey, }
      this.cardButtonHeight = {height: '45px'}
      this.homeImageCard = { marginLeft: '10.5px', width: '65px', marginBottom: '5px', }
      /*for notifications Listing*/
      this.notificationHeading = { marginRight: '1.8%', marginBottom: '1.8%', }
      this.notificationDateAndTime = { marginLeft: '2.8%', marginRight: '2.8%',lineHeight: '18px'}
      /* css for listing of data products */
      this.listCommonButton={padding: '1px', marginBottom: '10px',width: '13.33%', height: '30px'}
      this.listImageCard = { width: '12%', height: '103px', backgroundColor: this.bgColors.White };
      this.dataProductDescription = { marginLeft: '1%', marginBottom: '1%', };
      this.dataProductOtherDetails = { color: this.bgColors.DarkBlue,marginLeft: '1%', };
      this.listContentLength = { width: '82.86%', };
      this.inputStyle1 = { width: '25%', height: '25px', color: this.bgColors.DarkBlue, padding: '0.7%', borderWidth: '0px', };
      /* css for details page */
      this.detailsPageHeader = { backgroundColor: this.bgColors.HeaderColor, marginTop: '10px', }
      this.detailsPageContent = { marginBottom: '20px' }
      this.attributeName = { backgroundColor: this.bgColors.AttributeColor }
      /* data product header page*/
      this.detailsCard ={ marginTop: '10px',marginBottom: '10px',marginLeft: '10px',backgroundColor: this.bgColors.White };
      this.detailsImageCard ={ width: '13%',height: '130px',backgroundColor: this.bgColors.White,marginTop: '5px' };
      this.quantityStyle={marginLeft: '0.7%',marginRight: '10px'}
      this.buttonStyle= {width: '15%',marginLeft: '18px',marginBottom: '20px'}
      this.button1Style=Object.assign({},this.buttonStyle,{height: '40px'})
      this.button2Style=Object.assign({},this.buttonStyle,{height: '38px'})
      /* rating and review */
      this.ratingAndReviewButton ={width: '13%',height: '40px',marginLeft: '18px',marginBottom: '20px'}
      this.commentButton = { height: '30px', backgroundColor: this.bgColors.DarkGrey }
      this.submitcancelButton = { width: '13%', height: '30px'}
      this.hideonLoad = { visibility: 'hidden', display: 'none' }
      /* my purchases*/
      this.inlineStyle = { modal : { marginTop: '0px !important',marginLeft: 'auto',marginRight: 'auto'}};
      this.purchaseDataProductCardLayout = { width: '40px', height: '40px', backgroundColor: this.bgColors.White, marginTop:'5px', }
      /*creation of data product*/
      this.textBoxStyle={width: '100%',borderColor: this.bgColors.Grey}

      this.ErrorStyle = Object.assign({},this.fontSizeAccurate,this.fontFamily,this.fontWeight,{color: 'red'})
      /*step 1*/
        this.creationWidth = { width: '87%', marginLeft: '5%', borderColor: this.bgColors.Grey }
      this.step1Outline=  Object.assign({},this.creationOutline,{ height: '60%'})
        this.dpCreationImageStyle = {width: '50%', height: '70%',color: this.bgColors.DarkGrey, backgroundColor: this.bgColors.HeaderColor, marginTop: '17%',  marginLeft: '15%'}
        /* company info*/
      this.labelStyle1={marginTop:'1%'}
      this.labelStyle2={marginTop:'2%'}
      /*step 2*/
        this.step2Outline = Object.assign({},this.creationOutline,{ minHeight: '69vh'})
        this.addButton = { backgroundColor: this.bgColors.White, background: "url('/image/add.png') no-repeat top+5px left+5px", boxShadow: "0px 0px 2px 2px rgba(2, 2, 2, 0.24)", shadowOpacity: '24%', height: '35px', color: this.bgColors.Black,
        marginRight: '3%',paddingLeft: '3.5%'}
        this.deleteButton ={ backgroundColor: this.bgColors.White,background: "url('/image/delete.png') no-repeat top+5px left+5px", boxShadow: "0px 0px 2px 2px rgba(2, 2, 2, 0.24)", height: '35px',marginRight: '3%', color: this.bgColors.Black,
      paddingLeft: '3.5%'}
      /*step3 */
      this.step3Outline = Object.assign(this.creationWidth,{height: '50%'})
      /*step 4*/
      this.step4Outline = Object.assign(this.creationWidth,{minHeight: '450px'})
      /*step 5*/
      this.radioStyle={marginTop: '4%'}
      /* FYA about to expire*/
      this.submitButton={marginTop:'1%'}
      /* FYA modified price */
      this.radioHeader = { marginTop :'20px',marginBottom:'10px' }
      this.height100px = {minHeight:'100px'}
      this.height50px = {minHeight:'50px'}
  }
}

export default (new commonCss);
